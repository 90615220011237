import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql } from "gatsby";

import Content from "../components/content"
import Nav from "../components/nav"
import StyledA from "../components/styleda"
import StyledAB from "../components/styledabold"
import Responsive from "../components/responsive"
import { PageTitle, Subtitle } from "../components/titles"

import oscon from './img/oscon.jpg'
import graphqlSummit from './img/graphql-summit.jpg'
import roboticsCnc from './img/robotics-cnc.jpg'
import sfScalaInterview from './img/sfscala-interview.jpg'
import photo from './img/headshot.jpg' //Audrey_Cheng_Photo.jpg photo.jpeg
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// const scalaDaysTalks = {
//   2012: "http://skillsmatter.com/podcast/scala/making-games-and-solving-puzzles-in-scala",
//   2013: "https://www.youtube.com/watch?v=eyIbQn-mhsc",
//   2014: "https://www.youtube.com/watch?v=YuYSvoFY0dM",
//   2015: "https://www.youtube.com/watch?v=tbcXoSMsOGg",
//   2016: "https://www.youtube.com/watch?v=cngpLXViFCE",
//   2017: "https://www.youtube.com/watch?v=l8cR60eXEPo",
//   2018: "https://www.youtube.com/watch?v=MxRigUFmeVw"
// }

const news = [
  // [new Date("6/30/2022"), <>Our paper <StyledA href="https://www.shadaj.me/papers/crdt-synthesis.pdf">Synthesizing CRDTs from Sequential Data Types with Verified Lifting</StyledA> has been conditionally accepted to <StyledA href="https://2022.splashcon.org/track/splash-2022-oopsla">OOPSLA 2022</StyledA>!</>],
  // [new Date("1/1/2022"), <>Attending CIDR 2022, and learning how to pitch programming languages research to the database community</>],
  // [new Date("8/1/2021"), <>Started my PhD at UC Berkeley in the <StyledA href="https://rise.cs.berkeley.edu">RISELab</StyledA>, working on the <StyledA href="https://hydro-project.github.io">Hydro project</StyledA> under Alvin Cheung and Joe Hellerstein</>],
  // [new Date("4/1/2021"), <>Received the <StyledA href="https://www2.eecs.berkeley.edu/Students/Awards/113/">EECS Major Citation</StyledA> award!</>],
  // [new Date("3/1/2021"), <>Awarded the <StyledA href="https://www.nsfgrfp.org">NSF Graduate Research Fellowship!</StyledA></>]
  [new Date("6/15/2024"), <>Transaction scheduling paper accepted into VLDB 2024!</>],
  [new Date("12/22/2023"), <><StyledA href="https://dl-acm-org.libproxy.berkeley.edu/doi/pdf/10.14778/3636218.3636241">Mammoth Transactions</StyledA> accepted into VLDB 2024!</>],
  [new Date("3/23/2023"), <><StyledA href="https://www.usenix.org/system/files/osdi23-cheng.pdf">DeToX</StyledA> accepted into OSDI 2023!</>],
  [new Date("4/15/2022"), <><StyledA href="https://www.vldb.org/pvldb/vol15/p1965-cheng.pdf">TAOBench</StyledA> accepted into VLDB 2022!</>],
  // [new Date("4/1/2022"), <>Awarded <StyledA href="https://www.nsfgrfp.org">NSF Graduate Research Fellowship</StyledA>!</>],
  // [new Date("2/1/2022"), <>Awarded <StyledA href="https://research.facebook.com/blog/2022/2/announcing-the-recipients-of-the-2022-meta-phd-research-fellowship/">Meta PhD Research Fellowship</StyledA>!</>],
  // [new Date("8/15/2021"), <>Check out our Facebook Engineering <StyledA href="https://engineering.fb.com/2021/08/18/core-data/ramp-tao/">blog post</StyledA> on RAMP-TAO!</>],
  // [new Date("9/24/2021"), <>DeToX awarded <StyledA href="https://research.facebook.com/blog/2021/9/announcing-the-winners-of-the-2021-next-generation-data-infrastructure-request-for-proposals/">Meta Next-Generation Data Infrastructure Research Award</StyledA>!</>],
  [new Date("8/1/2021"), <><StyledA href="http://www.vldb.org/pvldb/vol14/p3014-cheng.pdf">RAMP-TAO</StyledA> awarded Best Industry Paper at VLDB 2021!</>]
];

class RotatePictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(s => {
        if (s.index + 1 >= this.props.images.length) {
          return { index: 0 };
        } else {
          return { index: s.index + 1 };
        }
      });
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.index >= this.props.images.length) {
      this.setState({ index: 0 });
    }
  }

  render() {
    const nextIndex = (this.state.index + 1) % this.props.images.length;
    const prevIndex = (this.state.index - 1 + this.props.images.length) % this.props.images.length;
    return (<>
      {this.props.children(this.props.images[prevIndex], 0, prevIndex)}
      {this.props.children(this.props.images[this.state.index], 1, this.state.index)}
      {this.props.children(this.props.images[nextIndex], 0, nextIndex)}
    </>);
  }
}

const IndexTitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 60px;
  @media (max-width: 1600px) {
    font-size: 50px;
    margin-top: 30px;
  }
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
  color: #2F80ED;
`;// #09b520;

const IndexSubtitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 45px;
  @media (max-width: 1600px) {
    font-size: 40px;
  }
  font-weight: 550;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2F80ED;
`;// #09b520;

const IndexSubSubtitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 40px;
  @media (max-width: 1600px) {
    font-size: 30px;
  }
  font-weight: 550;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2F80ED;
`;// #09b520;

const NoMarginP = styled.p`
  margin: 0px;
`;

const MarginP = styled.p`
  margin-left: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.0fr 2.2fr;
`;

const Pre = styled.pre`
  text-indent: 35px;
  margin:0;
`;

const Img = styled.img`
  width: calc(100% - 60px);
  position: "absolute";
  display: "inline-block";
  border-radius: 30px;
  box-sizing: border-box;
  margin-top: 40px;
  margin-left: 30px;
`;

export default class Index extends React.Component {
  render() {
    // const scalaDaysParents = Object.keys(scalaDaysTalks).map((key, i) => {
    //   return <Fragment key={i}>
    //     <StyledA href={scalaDaysTalks[key]}>{key}</StyledA>
    //     {(i < Object.keys(scalaDaysTalks).length - 1) && <span>, </span>}
    //   </Fragment>;
    // });

    // console.log(photo);
    // const mobileImages = [this.props.data.yellowstone, this.props.data.roboticsCnc, this.props.data.beach, this.props.data.graphqlSummit];
    // const desktopImages = [...mobileImages, this.props.data.sfScalaInterview];

    return (
      <div style={{
        background: "#fafcff", //
      }}>
        <Nav current="about"/>
        <Content>
        <Responsive comp={(big) => {
        return <Grid style={{
          display: big ? "grid" : "block"
        }}>
          <div style={{
            width: "100%",
            maxWidth: "400px", // big ? undefined : "450px",
            // height: "460px",
            // position: "relative",
            // borderRadius: "30px",
            // borderTopRightRadius: "30px",
            // overflow: "hidden",
            // paddingLeft: "200",
            marginLeft: "auto",
            marginRight: "auto",
            // transform: "translateZ(0)"
          }}>
          {/* <IndexTitle>Hello, I'm Audrey!</IndexTitle> */}

          <Img src={photo}/>
          {/* <div style={{ marginTop: 20, fontWeight: 500, fontSize: 20, marginLeft: 50}}>accheng at berkeley dot edu</div> */}
          {/* <Responsive comp={(big) => {
              return <RotatePictures images={big ? desktopImages : mobileImages}>
                {(image, opacity, key) => {
                  return <div style={{
                    position: "absolute",
                    top: 0, left: 0,
                    width: "100%",
                    height: 550,
                    opacity,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('${image}')`,
                    transition: "opacity 1.5s linear"
                  }} key={key}/>;
                }}
              </RotatePictures>
            }}/> */}
          {/* <IndexSubtitle>I explore the boundaries of programming</IndexSubtitle> */}
          </div>
          <div style={{
            width: big ? "calc(100% - 10px)" : "calc(100% - 50px)",
            // height: "460px",
            // position: "relative",
            // borderRadius: "30px",
            // overflow: "hidden",
            overflowX: "auto",
            // position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            // transform: "translateZ(0)"
          }}>
          <IndexTitle>Hello, I'm Audrey!</IndexTitle>
          {/* <IndexSubtitle>I explore the boundaries of programming</IndexSubtitle> */}
          {/* <Content> */}
          <div style={{ marginTop: 20, fontWeight: 500, fontSize: 20}}>
            I am a fifth-year PhD student in Computer Science at UC Berkeley in the <StyledA href="https://sky.cs.berkeley.edu/contact/">Sky Computing Lab</StyledA> advised by <StyledA href="https://people.eecs.berkeley.edu/~istoica/">Ion Stoica</StyledA> and <StyledA href="https://nacrooks.github.io">Natacha Crooks</StyledA>.
            My research focuses on transaction processing for database systems.
            {/* Most recently, I have built <StyledA href="https://www.vldb.org/pvldb/vol15/p1965-cheng.pdf">TAOBench</StyledA>, a new transactional benchmark that captures Meta's social network workload. */}

            {/* I am broadly interested in databases and distributed systems.  */}

            {/* I'm affiliated with the <StyledA href="https://rise.cs.berkeley.edu/">RISELab</StyledA>.  */}
            {/* My work on <StyledA href="https://www.vldb.org/pvldb/vol15/p1965-cheng.pdf">RAMP-TAO</StyledA> explored how to provide */}
            {/* atomicity guarantees at large scale.  */}
            {/* <p/> */}


            {/* <p/> */}

            Previously, I graduated with a BSE in Operations Research and Financial Engineering from Princeton University,
            where I was fortunate to work with <StyledA href="https://www.cs.princeton.edu/~wlloyd/">Wyatt Lloyd</StyledA> and <StyledA href="https://www.cs.princeton.edu/~li/">Kai Li</StyledA>.
            I have interned on the Themis team at Facebook, which focuses on providing stronger consistency and isolation guarantees for large-scale systems.
            I am grateful to be supported by a <StyledA href="https://www.nsfgrfp.org/">NSF Graduate Research Fellowship</StyledA>, a <StyledA href="https://research.facebook.com/blog/2022/2/announcing-the-recipients-of-the-2022-meta-phd-research-fellowship/">Meta PhD Research Fellowship</StyledA>,
            and a <StyledA href="https://grad.berkeley.edu/admissions/apply/fellowships-entering/">Berkeley Chancellor's Fellowship</StyledA>.
            </div>
            {/* </Content> */}
          </div>

          </Grid>
          }}/>
          {/* <div style={{
            width: "100%",
            height: 550,
            position: "relative",
            borderRadius: "30px",
            overflow: "hidden",
            transform: "translateZ(0)"
          }}>
            <Responsive comp={(big) => {
              return <RotatePictures images={big ? desktopImages : mobileImages}>
                {(image, opacity, key) => {
                  return <GatsbyImage image={getImage(image)} style={{
                    position: "absolute",
                    top: 0, left: 0,
                    width: "100%",
                    height: "550px",
                    opacity,
                    objectFit: "cover",
                    transition: "opacity 1.5s ease-in-out",
                    zIndex: opacity === 1 ? 2 : 1
                  }} key={key} alt=""/>;
                }}
              </RotatePictures>
            }}/>
          </div> */}
          <div style={{ marginTop: 0 }}>
            {/* I'm a second-year PhD student in Computer Science at UC Berkeley advised by <StyledA href="https://people.eecs.berkeley.edu/~istoica/">Ion Stoica</StyledA> and <StyledA href="https://nacrooks.github.io">Natacha Crooks</StyledA>.
            I am broadly interested in databases and distributed systems. I'm affiliated with the <StyledA href="https://rise.cs.berkeley.edu/">RISELab</StyledA>, and
            I am grateful to be supported by a <StyledA href="https://research.facebook.com/blog/2022/2/announcing-the-recipients-of-the-2022-meta-phd-research-fellowship/">Meta PhD Research Fellowship</StyledA>,
            a <StyledA href="https://grad.berkeley.edu/admissions/apply/fellowships-entering/">Berkeley Chancellor's Fellowship</StyledA>,
            and a <StyledA href="https://www.nsfgrfp.org/">NSF Graduate Research Fellowship</StyledA>.

            <p/>

            Previously, I graduated with a BSE in Operations Research and Financial Engineering from Princeton University,
            where I was fortunate to work with <StyledA href="https://www.cs.princeton.edu/~wlloyd/">Wyatt Lloyd</StyledA> and <StyledA href="https://www.cs.princeton.edu/~li/">Kai Li</StyledA>.
            I have interned on the Themis team at Facebook, which focuses on providing stronger consistency and isolation guarantees for large-scale systems. */}

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0",
            width: "calc(100vw - 100px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>

          <div style={{
            maxWidth: "calc(100% - 50px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}>
            <IndexSubtitle style={{
              // marginTop: "30px",
              textAlign: "center"
            }}>Latest News</IndexSubtitle>
            {news.map(([date, text], i) => {
              return <div style={{
                marginTop: i > 0 ? "10px" : undefined
              }} key={i}>
                {/* {new Intl.DateTimeFormat("default", {
                  month: "short",
                  year: "numeric",
                }).format(date)} {text} */}
                <div style={{
                  display: "inline-block",
                  width: "90px",
                  fontWeight: "300",
                  textAlign: "left",
                  paddingRight: "10px"
                }}>{new Intl.DateTimeFormat("default", {
                  month: "short",
                  year: "numeric",
                }).format(date)}</div>
                <div style={{
                  display: "inline-block",
                  width: "calc(100% - 100px)",
                  verticalAlign: "top"
                }}>{text}</div>
              </div>
            })}
          </div>

          <MarginP><div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0",
            width: "calc(100vw - 100px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>

          {/* <IndexSubtitle>Latest News</IndexSubtitle>
          <ul style={{
            marginTop: "10px"
          }}>
            <li>TAOBench accepted into VLDB 2022! (Apr. '22))</li>
            <li>Awarded NSF Graduate Research Fellowship! (Apr. '22)</li>
            <li>Awarded <StyledA href="https://research.facebook.com/blog/2022/2/announcing-the-recipients-of-the-2022-meta-phd-research-fellowship/">Meta PhD Research Fellowship</StyledA>! (Feb. '22)</li>
            <li>Check out our FB Engineering <StyledA href="https://engineering.fb.com/2021/08/18/core-data/ramp-tao/">blog post</StyledA> on RAMP-TAO! (Aug. '21)</li>
            <li><StyledA href="http://www.vldb.org/pvldb/vol14/p3014-cheng.pdf">RAMP-TAO</StyledA> awarded Best Industry Paper at VLDB 2021! (Aug. '21)</li>
          </ul> */}

          {/* <IndexSubtitle>Publications</IndexSubtitle> */}
          <IndexSubtitle style={{
              // marginTop: "30px",
              textAlign: "center"
            }}>Publications</IndexSubtitle>

          <div>
            <NoMarginP><b>Towards Optimal Transaction Scheduling</b></NoMarginP>
            <NoMarginP><span style={{ fontWeight: 600 }}>Audrey Cheng</span>, Aaron Kabcenell, Jason Chan, Xiao Shi, Peter Bailis, Natacha Crooks, Ion Stoica</NoMarginP>
            <NoMarginP>VLDB 2024</NoMarginP>
          </div>



          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>Optimizing LLM Queries in Relational Workloads</b></NoMarginP>
            <NoMarginP>Shu Liu,* Asim Biswal,* <span style={{ fontWeight: 600 }}>Audrey Cheng</span>,* Xiangxi Mo, Shiyi Cao, Joseph E. Gonzalez, Ion Stoica, Matei Zaharia</NoMarginP>
            <NoMarginP>[<StyledA href="https://arxiv.org/abs/2403.05821">arxiv</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>Mammoths are Slow: The Overlooked Transactions of Graph Data</b></NoMarginP>
            <NoMarginP><span style={{ fontWeight: 600 }}>Audrey Cheng</span>, Jack Waudby, Hugo Firth, Natacha Crooks, Ion Stoica</NoMarginP>
            <NoMarginP>VLDB 2024 [<StyledA href="https://dl-acm-org.libproxy.berkeley.edu/doi/pdf/10.14778/3636218.3636241">pdf</StyledA>][<StyledA href="https://github.com/jackwaudby/mammoths-are-slow">code</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>Take Out the TraChe: Maximizing (Tra)nsactional Ca(che) Hit Rate</b></NoMarginP>
            <NoMarginP><span style={{ fontWeight: 600 }}>Audrey Cheng</span>, David Chu, Terrance Li, Jason Chan, Natacha Crooks, Joseph M. Hellerstein, Ion Stoica, Xiangyao Yu</NoMarginP>
            <NoMarginP><StyledAB href="https://research.facebook.com/blog/2021/9/announcing-the-winners-of-the-2021-next-generation-data-infrastructure-request-for-proposals/">Meta Next-Generation Data Infrastructure Research Award</StyledAB></NoMarginP>
            <NoMarginP>OSDI 2023 [<StyledA href="https://www.usenix.org/system/files/osdi23-cheng.pdf">pdf</StyledA>][<StyledA href="https://github.com/audreyccheng/detox">code</StyledA>][<StyledA href="https://audreyccheng.com/blog/transaction-caching">blog post</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>TAOBench: An End-to-End Benchmark for Social Network Workloads</b></NoMarginP>
            <NoMarginP><span style={{ fontWeight: 600 }}>Audrey Cheng</span>, Xiao Shi, Aaron Kabcenell, Shilpa Lawande, Hamza Qadeer, Jason Chan, Harrison Tin, Ryan Zhao, Peter Bailis, Mahesh Balakrishnan, Nathan Bronson, Natacha Crooks, Ion Stoica</NoMarginP>
            <NoMarginP>VLDB 2022 [<StyledA href="https://www.vldb.org/pvldb/vol15/p1965-cheng.pdf">pdf</StyledA>][<StyledA href="https://github.com/audreyccheng/taobench">code</StyledA>][<StyledA href="https://engineering.fb.com/2022/09/07/open-source/taobench/">Meta blog post</StyledA>][<StyledA href="https://audreyccheng.com/blog/taobench">blog post</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>RAMP-TAO: Layering Atomic Transactions on Facebook’s Online TAO Data Store</b></NoMarginP>
            <NoMarginP><span style={{ fontWeight: 600 }}>Audrey Cheng</span> , Xiao Shi, Lu Pan, Anthony Simpson, Neil Wheaton, Shilpa Lawande, Nathan Bronson, Peter Bailis, Natacha Crooks, Ion Stoica</NoMarginP>
            <NoMarginP><StyledAB href="https://vldb.org/2021/?conference-awards">Best Industry Paper Award</StyledAB></NoMarginP>
            <NoMarginP>VLDB 2021 [<StyledA href="http://www.vldb.org/pvldb/vol14/p3014-cheng.pdf">pdf</StyledA>][<StyledA href="https://vimeo.com/667043993">talk</StyledA>][<StyledA href="https://engineering.fb.com/2021/08/18/core-data/ramp-tao/">blog post</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>Ownership: A Distributed Futures System for Fine-Grained Dynamic Tasks</b></NoMarginP>
            <NoMarginP>Stephanie Wang, Eric Liang, Edward Oakes, Benjamin Hindman, Frank Sifei Luan, <span style={{ fontWeight: 600 }}>Audrey Cheng</span>, Ion Stoica</NoMarginP>
            <NoMarginP>NSDI 2021 [<StyledA href="https://www.usenix.org/system/files/nsdi21-wang.pdf">pdf</StyledA>]</NoMarginP>
          </div>

          <div style={{ marginTop: "15px" }}>
            <NoMarginP><b>Gryff: Unifying Consensus and Shared Registers</b></NoMarginP>
            <NoMarginP>Matthew Burke, <span style={{ fontWeight: 600 }}>Audrey Cheng</span>, Wyatt Lloyd</NoMarginP>
            <NoMarginP>NSDI 2020 [<StyledA href="https://www.usenix.org/system/files/nsdi20-paper-burke.pdf">pdf</StyledA>][<StyledA href="https://github.com/matthelb/gryff">code</StyledA>]</NoMarginP>
          </div>

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0",
            width: "calc(100vw - 100px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>

          <IndexSubtitle style={{
            // marginTop: "30px",
            textAlign: "center"
          }}>Talks</IndexSubtitle>
          <b>“Take Out the TraChe: Maximizing (Tra)nsactional Ca(che) Hit Rate”</b>
          <ul style={{
            marginTop: "0px"
          }}>
            <li>Sky Lab Winter 2023 Retreat (Monterey, CA): Jan. 2023.</li>
            <li>Distributed Systems Meeting (Berkeley, CA): Nov. 2022.</li>
            <li>Sky Lab Summer 2022 Retreat (Lake Tahoe, CA): Jun. 2022.</li>
          </ul>

          <b>“TAOBench: An End-to-End Benchmark for Social Networking Workloads”</b>
          <ul style={{
            marginTop: "0px"
          }}>
            <li>Pinterest Infra Talks (Palo Alto, CA): Dec. 2022.</li>
            <li>HTAP Summit (Palo Alto, CA): Nov. 2022.</li>
            <li>PingCAP Meetup (Berkeley, CA and <StyledA href="https://www.youtube.com/watch?v=9oAS0U7nIpk">Online</StyledA>): Sep. 2022.</li>
            <li>Fifteenth TUC Meeting @ SIGMOD 2022 (<StyledA href="https://ldbcouncil.org/event/fifteenth-tuc-meeting/">Online</StyledA>): Jun. 2022.</li>
            <li>Berkeley Database Seminar (Berkeley, CA): Feb. 2022.</li>
            <li>Berkeley Progressive Systems Seminar (Online): Dec. 2021.</li>
            <li>Deep-Dive Talks @ Facebook (Online): Sep. 2021.</li>
          </ul>

          <b>“RAMP-TAO: Layering Atomic Transactions on Facebook’s Online TAO Data Store”</b>
          <ul style={{
            marginTop: "0px"
          }}>
            <li>LinkedIn Big Data Platforms (Online): Mar 2022.</li>
            <li>Meta Core Data Tech Talks (Online): Feb 2022.</li>
            <li>RISELab Winter 2022 Retreat (<StyledA href="https://rise.cs.berkeley.edu/retreat/winter-22-retreat/">Online</StyledA>): Jan 2022.</li>
            <li>Berkeley Databases Seminar (Online): May 2021.</li>
            <li>Berkeley Progressive Systems Seminar (Online): Apr. 2021.</li>
          </ul>

          <b>“Gryff: Unifying Consensus and Shared Registers”</b>
          <ul style={{
            marginTop: "0px"
          }}>
            <li>DB Engineering @ Facebook (Online): Aug. 2020.</li>
          </ul>

          <b>“Unifying Caching Objectives with Learning Relaxed Belady”</b>
          <ul style={{
            marginTop: "0px"
          }}>
            <li>Deep-Dive Talks @ Facebook (Online): Aug. 2020.</li>
            <li>Princeton Senior Thesis Oral Presentation (Online): May 2020.</li>
          </ul>

          <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0",
            width: "calc(100vw - 100px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>

          <IndexSubtitle style={{
            // marginTop: "30px",
            textAlign: "center"
          }}>Fellowships and Awards</IndexSubtitle>
          <ul style={{
            marginTop: "10px"
          }}>
            <li>NSF Graduate Research Fellowship (2022)</li>
            <li>Meta PhD Research Fellowship (2022)</li>
            <li>Berkeley Chancellor's Fellowship (2020)</li>
            <li>Berkeley EECS Excellence Award (2020)</li>
            <li>Princeton Kenneth H. Condit '13 Prize (2020)</li>
            <li>Princeton Phi Beta Kappa, Sigma Xi, Tau Beta Pi (2019, 2020)</li>
            <li>Princeton George B. Wood Legacy Junior Prize (2019)</li>
            <li>Princeton Shapiro Prize for Academic Excellence (2017, 2018)</li>
          </ul>


          {/* <div style={{ marginTop: 20 }}>
            I'm a first-year PhD student co-advised by <StyledA href="https://people.eecs.berkeley.edu/~akcheung/">Alvin Cheung</StyledA> and <StyledA href="https://dsf.berkeley.edu/jmh/">Joe Hellerstein</StyledA> at UC Berkeley, where I research programming languages and distributed systems. I'm grateful to be supported by an <StyledA href="https://www.nsfgrfp.org/">NSF Graduate Research Fellowship</StyledA>. Previously, I received my BS in EE/CS at UC Berkeley.
            I've interned at <StyledA href="https://github.com/tensorflow/swift/blob/master/proposals/modern-layer-api/LayerApiPrototypes.md">Google Brain</StyledA>, <StyledA href="https://engineering.fb.com/category/production-engineering/">Facebook</StyledA>, <StyledA href="https://blog.apollographql.com/tutorial-graphql-subscriptions-client-side-40e185e4be76">Apollo GraphQL</StyledA>, <StyledA href="http://engineering.khanacademy.org/posts/interning-at-khan-academy.htm">Khan Academy</StyledA>, <StyledA href="https://building.coursera.org/blog/2014/08/14/my-internship-at-coursera/">Coursera</StyledA>, and <StyledA href="https://github.com/payalabs/scalajs-react-mdl">Paya Labs</StyledA>, where I worked with GraphQL, Rust, React, and Scala.js to develop open-source libraries, education platforms, and large-scale infrastructure. */}



            {/* I host many open-source libraries and projects on <StyledA href="http://github.com/shadaj">GitHub</StyledA>, and have a <StyledA href="http://youtube.com/shadajProgramming">channel on YouTube</StyledA>.
            I've presented a keynote at <StyledA href="https://www.youtube.com/watch?v=Co8V3VSRvYM">OSCON 2014</StyledA> and at Scala Days ({scalaDaysParents}), <StyledA href="https://www.youtube.com/watch?v=cZtQgK83AqQ">GraphQL Summit</StyledA>, <StyledA href="https://www.youtube.com/watch?v=aMjyhjJ-pJc">Scale By the Bay</StyledA>, and various other technical conferences.
            In high school, I was the Co-President of <StyledA href="http://lynbrookrobotics.com/">Lynbrook Robotics</StyledA>, where I led a competitive robotics team of over 100 students. I continue to be involved with the team, where I focus on introducing students to advanced control theory topics and software design principles. */}
          </MarginP>
          </div>

          <MarginP> <div style={{
            marginTop: "30px",
            borderTop: "1px solid #a0a0a0",
            width: "calc(100vw - 100px)",
            marginLeft: "auto",
            marginRight: "auto"
          }}/>

          <IndexSubSubtitle>Contact</IndexSubSubtitle>
          You can reach me at <b>accheng</b> <i>at</i> <b>berkeley</b> <i>dot</i> <b>edu</b>. If you are a Berkeley undergrad interested in research, please contact me via email.

          <div style={{
            marginTop: "50px",
            textAlign: "right",
            fontSize: "medium",
            fontWeight: "100",
          }}>
          Site inspired by <StyledA href="https://www.shadaj.me/">shadaj.me</StyledA>
          </div>
          </MarginP>
        </Content>
      </div>
    )
  }
}


export const query = graphql`
  query {
    yellowstone: file(relativePath: { eq: "img/yellowstone.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    roboticsCnc: file(relativePath: { eq: "img/robotics-cnc.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    sfScalaInterview: file(relativePath: { eq: "img/sfscala-interview.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    beach: file(relativePath: { eq: "img/beach.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    graphqlSummit: file(relativePath: { eq: "img/graphql-summit.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
